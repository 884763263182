.ranking-board-background {
  width: 100%;
  height: 100%;
  filter: blur(22px);
  background-image: url("../../../../../public/banner.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.ranking-board-root {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  box-shadow: 0px 5px 5px 5px;
  .ranking-board-header {
    text-align: center;
    padding: 2rem;
    color: rgb(225, 221, 221);
    box-shadow: 0px 0px 50px 0px;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .ranking-board-card {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    height: 150px;
    width: 250px;
    align-items: center;
    font-size: 1rem;
    color: rgb(225, 221, 221);
    border-radius: 20px;
    box-shadow: 20px 20px 200px 20px;
    // background: #1f2a40;
    background-color: rgba(0, 0, 0, 0.4); /* Black w/opacity/see-through */

    margin: 0 auto;
    margin-bottom: 5rem;
  }
}
