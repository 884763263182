.tools-page-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
}

.tools-page-header {
  // font-family: "Press Start 2P", cursive;
  display: flex;
  margin-top: 50px;
  margin-left: 8%;
  font-size: 60px;
  font-weight: bolder;
  color: #648eb3 !important;
  filter: blur(1px);
}

.tools-page-textBox {
  // font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 35px;
  margin-top: 50px;
  position: relative;
  text-align: center;
}
.tools-page-h3 {
  color: white;
  text-shadow: 1px 1px 4px black;
}

.tools-page-span {
  font-size: 24px;
  // font-family: "Open Sans", sans-serif;
  color: #cdd6f6;
  margin-left: 25%;
  text-shadow: 1px 1px 4px black;

  width: 50%;
}

.tools-page-imagebox {
  margin-right: 100px;
  z-index: 99;
  display: none;
}

.tools-page-imagebox2 {
  position: absolute;
  right: 0;
  top: -9%;
  z-index: -1;
  height: 100px;
}

.tools-types-container {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  margin-top: 80px;
  z-index: 9;
}

.tools-types-headers {
  // font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-shadow: 1px 1px 4px black;
  font-size: 42px;
  margin: 22px 0;
  color: white;
}

.tools-types-spans {
  font-size: 20px;
  // font-family: "Open Sans", sans-serif;
  color: #cdd6f6;
  font-weight: bold;
  text-shadow: 1px 1px 3px black;
  line-height: 1.3;
}

.tools-buttons {
  color: white !important;
  font-size: 21px !important;
  text-shadow: 2px 2px 4px rgb(0, 0, 0) !important;
  width: 300px !important;
}

.tools-buttons:hover {
  font-weight: bold;
  width: 500px !important;
  background-color: #648eb3 !important;
}
.tools-types-container-2 {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row-reverse;
  margin-top: 80px;
  z-index: 9;
}

@media only screen and (max-width: 800px) {
  .tools-page-header {
    font-size: 2.5rem;
  }

  .tools-types-headers {
    font-size: 30px;
    margin-bottom: 20px;
    margin-top: 30px;
    padding: 0 20px;
    text-align: center;
  }

  .tools-types-spans {
    font-size: 18px;
    text-align: center;
  }
  .tools-types-risk-img {
    margin: 0 auto;
    width: 185px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .tools-types-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;

    .tools-types-wrapper {
      margin: 0 20px;
    }
    .button-wrap {
      display: flex;
      justify-content: center;
    }
  }
  .tools-types-container-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    .tools-types-wrapper {
      margin: 0 20px;
    }
    .button-wrap {
      display: flex;
      justify-content: center;
    }
  }
}
