.risk-policy-page-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
  width: 100%;
  align-items: center;
}

.risk-policy-page-header {
  // font-family: "Press Start 2P", cursive;
  color: #648eb3 !important;
  font-size: 60px;
  left: 5%;
  margin-left: 40px;
  filter: blur(1px);
  font-weight: bolder;
  position: absolute;
}
.risk-policy-page-textBox {
  background-color: white;
  margin-top: 150px;
  position: relative;
  width: 50%;
  padding: 50px;
  box-sizing: border-box;
}

.risk-policy-page-h3 {
  color: white;
  text-shadow: 1px 1px 4px black;
}
.risk-policy-page-span {
  font-size: 18px;
  // font-family: "Open Sans", sans-serif;
  color: black;
  text-shadow: 0px 0px 1px black;
  text-align: center;
}
