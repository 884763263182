.wallet-page-root-container-parent{
    width: 100%;
    background: #131722;
    position: relative;
    height: 500px;
    @media only screen and (max-width: 667px) {
        height: 1000px;
    }
}
.wallet-page-root-container{
    width: 70%;
    height: 75%;
    margin: 0 auto;
    display: flex;
    position: absolute;
    top: 3%;
    left: 0;
    right: 0;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #07232E;  
    padding-top: 30px;
    padding-bottom: 50px;
    border-radius: 5px;
    border: 1px solid rgb(83, 83, 83);
    padding-right: 30px;
    @media only screen and (max-width: 667px) {
        height: 100%;
    padding-right: 0px;

    }
    .wallet-page-wallet-info-wrapper{
        margin: 0 auto;
        display: flex;
        justify-content:space-between;
        align-items: center;
        flex-direction: row;
        padding-left: 10%;
        @media only screen and (max-width: 667px) {
            justify-content: center;
            flex-direction: column;
            padding-left: 0%;
        }
        .info-container {
            margin-right:100px;
            display: flex;
    justify-content: center;
    flex-direction: column;
    @media only screen and (max-width: 667px){
        margin: 0 auto;
}

            .info-container-header{
                color: white;
                margin-bottom: 10px;
                font-size: 1.5rem;
                @media only screen and (max-width: 667px) {
                    font-size: 1rem;
                }
            }
           p { 
            font-family: "Roboto", sans-serif !important;
            font-weight: 400;
            line-height: 1.5;
            word-wrap: break-word;
            text-align: center;
            box-sizing: border-box;
            color: #69ab00;
            font-size: 22px;
            @media only screen and (max-width: 667px) {
                font-size: 1rem;
            }
           }
        }
    }
    .wallet-page-transaction-components-container{
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: row;
        @media only screen and (max-width: 667px){
                flex-direction: column
        }
        

        .transaction-component-wrapper {
            margin-top: 60px;
            margin-left: 40px;
            width: 40%;
            background-color:#0D6372;
            padding: 10px;
            height: 100%;
            text-align: left;
            color: #fff;
            word-wrap: break-word;
            box-sizing: border-box;
            padding-right: 0.75rem;
            padding-left: 0.75rem;
            border-radius: 10px;
            border: 1px solid rgb(83, 83, 83);
            height: 250px;
            width: 300px;
            padding-top: 20px;
            text-align: center;
            opacity         : 0.5;
            transition      : 1s ease;
            @media only screen and (max-width: 667px){
                height: 250px;
                width: 200px;
                margin: 1rem auto
            }
            &:hover {
                opacity   : 1;
                transition: 1s ease;
                cursor    : pointer;

                .transaction-component-box-header{
                color: white;
                }
                span {
                    color: white;
                }
            }

            .transaction-component-svg{
                width: 120px;
                height: 120px;
                margin-bottom: 22px;
            }

            .transaction-component-box-header{
                color:grey;
                font-weight: 500;
                line-height: 1.2;
                font-size: 18px;
                margin-bottom: 10px;
            }

            span {
                font-size: 15px;
                line-height: 1.2;
                font-weight: 400;
                text-align: center;
                color: grey;
            }
        }
    }
    
}

