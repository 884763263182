.account-types-container-parent {
  background: #131722;
  padding: 20px 0px;
  .account-types-card-content {
    text-align: center;
    .account-types-card-typography {
      color: white;
      font-weight: bold;
    }
  }
  .account-types-card-container {
    margin: 4px 22px;
    width: 300px;
  }
  .account-types-card-action-area {
    background: #0d6372;
  }
}

.account-types-container {
  background: #131722;
  font-size: 12px;
  color: white;
  width: 98%;
  margin: 0 auto;
  background-color: #07232e;
  border-radius: 5px;
  border: 1px solid #535353;
}

.account-types-header {
  margin-left: 4rem;
  padding-top: 20px;
}
.account-types-cards {
  margin: 30px 0;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}
.account-types-card-actions {
  justify-content: center;
  background: #0d6372;
  border-top: 1px solid white;
  background-color: #039e93;
}
.account-types-p {
  font-size: 1rem;
  margin-top: 1rem;
  color: rgb(206, 201, 201);
}

@media only screen and (max-width: 658px) {
  .account-types-header {
    margin: 0;
    padding: 12px;
    text-align: center;
  }
  .account-types-cards {
    margin-top: 8px;
    flex-direction: column;
    align-items: center;
  }
}
