.react-reveal {
  color: white;
}
.div-container {
  color: #d44206;
  font-weight: bold;
  font-size: 70px;
  margin-top: 90px;

}
body, html {
  height: 100%;
  background: #fff;
}

.pulse-container {
  height: 100%;
  margin: 0 auto;
  margin-right: 20rem;
    align-self: end;
}

.pulse-button {
  position: relative;
  border: none;
  font-size: 3rem;
  padding: 2rem;
  border-radius: 1rem;
  background:linear-gradient(#00B968, black);
  color: white;
  cursor: pointer;
  box-shadow: 0 0 0 0 #00B968;
  animation: pulse 2s infinite cubic-bezier(0.1, 1, 0.1, 0.8);
}

.pulse-button:hover {
  -webkit-animation: none;
     -moz-animation: none;
      -ms-animation: none;
          animation: none;
}

@-webkit-keyframes pulse {to {box-shadow: 0 0 0 45px rgba(188, 132, 32, 0);}}
@-moz-keyframes pulse {to {box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);}}
@-ms-keyframes pulse {to {box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);}}
@keyframes pulse {to {box-shadow: 0 0 0 20px rgba(232, 76, 61, 0);}}

@-webkit-keyframes pulseText {to {text-shadow: 0 0 100px rgba(188, 132, 32, 0);}}
@-moz-keyframes pulseText {to {text-shadow: 0 0 100px rgba(232, 76, 61, 0);}}
@-ms-keyframes pulseText {to {text-shadow: 0 0 100px rgba(232, 76, 61, 0);}}
@keyframes pulseText {to {text-shadow: 0 0 100px rgba(232, 76, 61, 0);}}

.pulse-text {
  font-size: 1rem;
  font-weight: 800;
  font-style: italic;
  color: #000;
  text-transform: uppercase;
  text-shadow: 100 100 100 #bc2085;
  -webkit-animation: pulseText 3s infinite ease;
  -moz-animation: pulseText 1.25s infinite ease;
  -ms-animation: pulseText 1.25s infinite ease;
  animation: pulseText 3s infinite ease;
  transition: color 0.5s ease;
}
.inner-div-container {
  position: relative;
  padding: 330px 20px;
  background: rgba(0, 0, 0, 0.204)
    url("../../../public/banner.png");
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  background-size: cover;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.landingPageFirstHeader {
  color: white!important;
  font-size: 3.3rem;
  filter: blur(0.7px);
  font-weight: bolder;
  text-shadow: 7px 7px 1px black;
  position: absolute;
  right: 3%;
  top: 10%;
}
.landingPageSecondHeader {
  color: #648eb3;
  text-shadow: 7px 7px 1px black;
  filter: blur(0.6px);
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 20px;
}

.landingPageCSFC {
  color: #d2d2d2;
  position: absolute;
  top: 25%;
  right: 6%;
  text-shadow: 7px 7px 1px black;
  font-size: 2.3rem;
}
.landingPageThirdHeader {
  font-size: 35px;
  color: #d2d2d2;
  text-shadow: 7px 7px 1px black;
}

@media only screen and (max-width: 667px) {
  .div-container {
    font-size: 0px;
    margin-top: 0;
  }
  .pulse-container {
    display: none;
  }
  .inner-div-container {
    background-image: none;
    padding: 0;
    margin-top: 100px;
    padding: 0 20px;
  }
  .image-background-landingPage {
    visibility: hidden;
    height: 400px;
  }
  .landingPageFirstHeader {
    font-size: 40px;
  }
  .landingPageSecondHeader {
    font-size: 30px;
  }
  .landingPageCSFC {
    font-size: 30px;
  }
  .landingPageThirdHeader {
    font-size: 20px;
  }
}
