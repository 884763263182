.with-draw-page-root-container-parent {
	width: 100%;
	background: #131722;
	padding-top: 19px;
	// position: relative;
	height: 620px;
	@media only screen and (max-width: 667px) {
		height: 100%;
	}
}
.with-draw-container {
	display: flex;
	.with-draw-req {
		width: 60%;
		// margin-top: 8px;
		padding: 0 10px;
		@media only screen and (max-width: 667px) {
			width: 100%;
			padding: 0;
		}
	}
	.with-draw-history {
		width: 100%;
		padding: 0 10px;
		@media only screen and (max-width: 667px) {
			margin-top: 30px;
			padding: 0;
		}
	}
	@media only screen and (max-width: 667px) {
		flex-direction: column;
	}
}

.with-draw-page-root-container {
	width: 90%;
	height: 85%;
	margin: 0px auto;

	align-items: center;
	justify-content: space-between;
	background-color: #07232e;
	padding-top: 20px;
	padding-bottom: 50px;
	border-radius: 5px;
	border: 1px solid rgb(83, 83, 83);
	padding-left: 1rem;
	padding-right: 1rem;
}

.with-draw-page-container-header {
	color: white;
	border-bottom: 1px solid rgba(226, 232, 245, 0.1);
	padding-bottom: 10px;
	width: 100%;
	margin-bottom: 20px;
}

.with-draw-page-payment-method-header {
	color: white;
	font-size: 24px;
	margin-bottom: 10px;
}

.with-draw-page-withdraw-header {
	color: white;
	font-size: 19px;
	margin-bottom: 10px;
	text-align: center;
}

.with-draw-page-request-wrapper {
	span {
		color: white;
		font-size: 18px;
		margin-bottom: 20px;
	}
	textarea {
		// width: 100%;
		color: white;
		height: 100px;
		padding: 12px 20px;
		box-sizing: border-box;
		border: 1px solid rgb(83, 83, 83);
		border-radius: 4px;
		background: #07232e;
		font-size: 16px;
		resize: none;
		margin-bottom: 20px;
	}
	.MuiFormControl-root {
		.MuiTypography-root {
			color: white !important;
		}
		label {
			color: white;
		}

		div {
			color: white;
		}

		.before-component {
			color: white !important;
		}

		.MuiInput-root {
			// width: 200%;
			color: black;
			margin-bottom: 20px !important;
			&:focus {
				outline: none;
				border: none;
				border-bottom: 0.1rem solid #08b786;
			}

			&:after {
				border-bottom: 2px solid #08b786;
			}
		}

		.with-draw-page-payment-method-button {
			// width: 100% !important;
			margin-top: 30px;
			background-color: #08b786 !important;

			&:hover {
				background-color: #087959 !important;
			}
		}
	}
}
