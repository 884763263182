.deposit-page-root-container-parent {
    width     : 100%;
    background: #131722;
    position  : relative;
    height    : 400px;

    .deposit-page-root-container {
        width           : 70%;
        height          : 75%;
        margin          : 0 auto;
        position        : absolute;
        top             : 3%;
        left            : 0;
        right           : 0;
        align-items     : center;
        justify-content : space-between;
        background-color: #07232E;
        padding-top     : 15px;
        padding-bottom  : 50px;
        border-radius   : 5px;
        border          : 1px solid rgb(83, 83, 83);
        padding-left    : 1rem;
        padding-right   : 1rem;

        .deposit-page-container-header {
            height        : 5%;
            text-align    : left;
            color         : white;
            font-size     : 14;
            border-bottom : 1px solid rgba(226, 232, 245, 0.1);
            padding-bottom: 10px;
            width         : 100%;
            margin-bottom : 40px;
        }

        .deposit-page-payment-gate-container {
            display: flex;
            justify-content: space-around;
            flex-direction: row;
            height: 100%;
        }
        .deposit-page-payment-method-header {
            width        : 90%;
            text-align   : left;
            color        : white;
            font-size    : 24px;
            margin-bottom: 50px;

        }

        .deposit-page-credit-card-container-active {
            height          : 40%;
            color           : black;
            width           : 40%;
            padding-left    : 2rem;
            border          : 1px solid rgb(83, 83, 83);
            padding-top     : 1.5rem;
            background-color: #08B786;
            border-radius   : 10px;
            text-align      : center;
            padding-left    : 0;
            opacity         : 1;
            transition      : 1s ease;
            cursor: pointer;

            .MuiSvgIcon-root {
                font-size: 45px;
                color    : white;
                margin   : 0 auto;
            }

            h5 {
                font-size: 15px;
                color    : white;
            }
        }

        .deposit-page-credit-card-container {
            height          : 40%;
            color           : white;
            width           : 40%;
            padding-left    : 2rem;
            border          : 1px solid rgb(83, 83, 83);
            padding-top     : 1.5rem;
            background-color: #0D6372;
            border-radius   : 10px;
            text-align      : center;
            padding-left    : 0;
            opacity         : 0.5;
            transition      : 1s ease;

            &:hover {
                opacity   : 1;
                transition: 1s ease;
                cursor    : pointer;

                .MuiSvgIcon-root {
                    font-size: 45px;
                    color    : white;
                    margin   : 0 auto;
                }

                h5 {
                    font-size: 15px;
                    color    : white;
                }
            }

            .MuiSvgIcon-root {
                font-size: 45px;
                color    : grey;
                margin   : 0 auto;
            }

            h5 {
                font-size: 15px;
                color    : grey;
            }
        }
    }


}

.deposit-page-root-container-parent-active {
    width         : 100%;
    background    : #131722;
    position      : relative;
    height        : 300px;
    margin-bottom : 30px;
    padding-bottom: 30px;

    .deposit-page-root-container-active {
        width           : 70%;
        height          : 75%;
        margin          : 0 auto;
        position        : absolute;
        top             : 3%;
        left            : 0;
        right           : 0;
        align-items     : center;
        justify-content : space-between;
        background-color: #07232E;
        padding-top     : 15px;
        padding-bottom  : 50px;
        border-radius   : 5px;
        border          : 1px solid rgb(83, 83, 83);
        padding-left    : 1rem;
        padding-right   : 1rem;

        .depositInput {
            width: 30%
        }
        .depositButton {
            width: 15%;
            height: 30px;
        }
        .depositForm {
            display: flex;
            flex-direction: column;
        };

    }

    .deposit-page-container-header-active {
        height        : 5%;
        text-align    : left;
        color         : white;
        font-size     : 14;
        border-bottom : 1px solid rgba(226, 232, 245, 0.1);
        padding-bottom: 10px;
        width         : 100%;
        margin-bottom : 40px;
    }

    .deposit-page-payment-method-header-active {
        width        : 90%;
        text-align   : left;
        color        : white;
        font-size    : 24px;
        margin-bottom: 30px;
        
    }

    .MuiFormControl-root {
        .MuiTypography-root{
            color: white !important;
        }
        label {
            color: white;
        }

        div {
            color: white;
        }

        .before-component {
            color: white !important;
        }

        .MuiInput-root {
            width: 20%;
            color: black;
            
            &:focus {
                outline: none;
                border: none;
                border-bottom: .1rem solid #08B786;
            }

            &:after {
                border-bottom: 2px solid #08B786;
            }

            
        }

        .deposit-page-payment-method-button{
            width: 20% !important;
            margin-top: 30px;
            background-color:#08B786 !important;

            &:hover {
                background-color:#087959 !important;
 
            }
        }
    }
}