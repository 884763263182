.navbar {
  box-shadow: none !important;
}

.navbar-on-scroll {
  background: linear-gradient(360deg, #353535, #000000);
  box-shadow: 0px 5px 15px 2px rgba(0,0,0,0.7);
}
.header-logo {
    display: flex;
    justify-content: center;
 
}