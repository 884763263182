.orders-table-root-container {
  .MuiTableCell-head {
    // background-color:#131722 !important;
    background-color: #131722 !important;
  }
  //   max-height: 500px;
  //   overflow-y: auto;
  // border: 2px solid grey !important;
  // border-radius: 4px !important;
  ::-webkit-scrollbar {
    display: none;
  }
}
