.payment-footer-container {
    display: flex;
    margin: 0 auto;
    .payment-footer-ul {
        display: flex;
        justify-content: space-between;
    }
    li {
        margin: 2rem;
    }
    .payment-footer-img {
width: 100px; height: 60px;  border-radius: 0 ;
    }
}