.our-locations-page-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.our-locations-page-background {
}
.our-locations-page-header {
  background: rgba(0, 0, 0, 0) url("../../../public/ourLocations.jpg");
  // font-family: "Press Start 2P", cursive;
  display: flex;
  margin-top: 80px;
  font-size: 2rem;
  width: 100%;
  height: 30rem;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0px 0px 0px 200px;
  filter: blur(0px);
  .our-locations-page-header-content {
    font-size: 2rem;
    color: white;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin: 10rem 2rem auto;
    padding: 1rem;
    text-shadow: 1px 1px 1px;
    font-weight: 500;
    background: radial-gradient(#212121, rgb(0 0 0 / 39%));
    border-radius: 3rem;
  }
}
.our-locations-images-wrapper {
  margin-right: 20px;
}
.our-locations-types-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  background: radial-gradient(#335cafcc, rgb(0 0 0 / 78%)) !important;
  margin-top: 50px;
  z-index: 9;
}
.our-locations-types-container-reverse {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row-reverse;
  background: radial-gradient(#335cafcc, rgb(0 0 0 / 78%)) !important;
  margin-top: 100px;
  margin: 0 auto;
  z-index: 9;
  width: 100%;
}

.our-locations-main-container {
  background: linear-gradient(45deg, rgb(0 0 0 / 60%), transparent);
  height: 13rem;
  border-radius: 10rem;
  width: 60rem;
}
.our-locations-types-headers {
  // font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-shadow: 1px 1px 4px black;
  font-size: 42px;
  color: white;
}

.our-locations-types-spans {
  font-size: 1.2rem;
  // font-family: "Open Sans", sans-serif;
  color: #cdd6f6;
  font-weight: bold;
  text-shadow: 1px 1px 3px black;
}

.our-locations-buttons {
  color: white !important;
  font-size: 21px !important;
  text-shadow: 2px 2px 4px rgb(0, 0, 0) !important;
  width: 300px !important;
}

.our-locations-buttons:hover {
  font-weight: bold;
  width: 500px !important;
  background-color: #648eb3 !important;
}

.our-locations-page-h3 {
  color: white;
  font-size: 40px !important;

  text-shadow: 1px 1px 4px black;
}

.our-locations-address-phone-container {
  display: flex;
  flex-direction: column;
  margin: 2rem;
}
@media only screen and (max-width: 667px) {
  .our-locations-page-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 10px;
    align-items: center;
    text-align: center;

    .our-locations-types-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      z-index: 9;

      margin-top: 60px;
    }
    .our-locations-types-container-reverse {
      display: flex;
      justify-content: center;
      flex-direction: column-reverse;
      width: 100%;
      z-index: 9;
    }
    .our-locations-page-header {
      font-size: 30px;
      text-align: center;
      margin: 50px 50px auto;
    }

    .our-locations-types-headers {
      font-size: 30px;
      margin-bottom: 30px;
      margin-top: 30px;
    }

    .our-locations-types-spans {
      font-size: 17px;
    }

    .our-locations-text-container {
      margin: 0 auto;
    }
    .risk-text {
      margin-bottom: 20px;
    }
  }
}
